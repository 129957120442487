const appConfig = {
  isDevelopment: process.env.NODE_ENV === "development",

  tnlmEnv: process.env.REACT_APP_NODE_ENV,
  tnlmApiKey: "9x1ixWYqCYVSbHz59Oynlgbw5CXCL8jI",
  tnlmSentryDSN: process.env.REACT_APP_SENTRY_DSN,
  tnlmGAID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  tnlmBaseApi: process.env.REACT_APP_BASE_URL_API,

  hotjarId: process.env.REACT_APP_HOTJAR_ID,
};

export default appConfig;
