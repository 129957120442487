const initialState = {
  project: {
    exportType: 1,
    exportFormat: 1,
    exportCombine: 0,
    visibility: 0,
    roles: [],
    buildingMaterials: [],
    columns: [],
    exportCompanyDataAsColumn: 0,
    tabActive: null,
    refresh: null,
    exportAllNotes: 0,
    exportAllTask: 0,
    excludeResignedContact: 0,
    description: "",
    staticCompanyRole: 0,
  },
  company: {
    exportType: 2,
    exportFormat: 1,
    exportCombine: 0,
    visibility: 0,
    roles: [],
    columns: [],
    tabActive: null,
    refresh: null,
    exportAllNotes: 0,
    exportAllTask: 0,
    excludeResignedContact: 0,
    includeMatchedProject: 0,
    description: "",
  },
  tabActive: "project",
};

export default initialState;
